import { GrMoney } from "react-icons/gr";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { TbCalendarDollar } from "react-icons/tb";

export const InvoiceInfo = () => {
  return (
    <>
      <div className="grid lg:grid-cols-3 sm:grid-cols-1 lg:space-y-0 space-y-7 gap-3">
        {/* Card 1 */}
        <div className=" p-6 bg-white dark:bg-primaryDark rounded-xl shadow-md flex-col justify-start items-start gap-4 inline-flex">
          <div className="justify-center items-center gap-4 inline-flex">
            <div className="text-zinc-700 text-md dark:text-titleDark  font-medium flex items-center ">
              Deuda Pendiente
            </div>
          </div>
          <div className="flex items-center mx-4 text-red-600 text-4xl font-medium ">
            {/* {getAmount['totalQuantity']} */}
            $4,00
            <GrMoney className="w-8 h-8 mx-2 " />
          </div>
        </div>
        {/* Card 2 */}
        <div className=" p-6 bg-white dark:bg-primaryDark rounded-xl shadow-md flex-col justify-start items-start gap-4 inline-flex">
          <div className="justify-center items-center gap-4 inline-flex">
            <div className="text-zinc-700 text-md dark:text-titleDark  font-medium flex items-center ">
              Saldo a Favor
            </div>
          </div>
          <div className="flex items-center mx-4 text-lime-600 text-4xl font-medium ">
            {/* {getAmount['totalQuantity']} */}
            $0,00
            <MdOutlineAccountBalanceWallet className="w-8 h-8 mx-2 " />
          </div>
        </div>
        {/* Card 3 */}
        <div className=" p-6 bg-white dark:bg-primaryDark rounded-xl shadow-md flex-col justify-start items-start gap-4 inline-flex">
          <div className="justify-center items-center gap-4 inline-flex">
            <div className="text-zinc-700 text-md dark:text-titleDark  font-medium flex items-center ">
              Fecha de Facturación
            </div>
          </div>
          <div className="flex items-center mx-4 text-neutral-700 text-4xl xl:text-3xl font-medium ">
            {/* {getAmount['totalQuantity']} */}
            12-06-2024
            <TbCalendarDollar className="w-8 h-8 mx-2 " />
          </div>
        </div>
      </div>
    </>
  );
};
