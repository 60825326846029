import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BottonsPayments } from "./components/table/BottonsPayments";
import { CheckFilterPayments } from "./components/filters/CheckFilterPayments";
import { SinceUntilPayments } from "./components/filters/SinceUntilPayments";
import { TablePayment } from "./components/table/TablePayments";
import { PaymentParams } from "../../../params/payment/payments/paymentParams";
import { AppDispatch } from "../../../../../store/store";
import { useAllParams } from "../../../../../hooks/useAllParams";
import { getPaymentRequest, PaymentsThunks } from "../../../../../store/payment/thunks";
import { TemplateTableLayout } from "../../../layout/TemplateTableLayout";
import { BanksDestinyOriginPayments } from "./components/filters/BanksDestinyOriginPayments";
import { CompaniesFilterPayments } from "./components/filters/CompaniesFilterPayments";
import { CardInfoPayments } from "../../companies/company/components/CardInfoPayments";
import { DashboardParams } from "../../../params/dashboard/DashboardParams";
import { firstDayMonth } from "../../../services/getToday";
import moment from "moment";
import { DashboardParamsThunks } from "../../../../../store/dashboard/thunks";
import { create } from "zustand";
import { Payment } from "../../../interfaces/PaymentInterfaces";
import { queryOptions, useQueryClient } from "@tanstack/react-query";

export const useStorePaymentCore = create((set) => ({
  payments: [],
  count: 0,
  setPayments: (payments: Payment[], count: number) =>
    set(() => ({ payments, count })),

  reset: () => set(() => ({ payments: [], count: 0 })),
}));

const PaymentCore = () => {
  const queryClient = useQueryClient();
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { count, setPayments, payments } = useStorePaymentCore(
    (state: any) => state
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleConsultation = () => {
    const param = new PaymentParams();
    params.status && (param.status = params.status);
    params.page && (param.page = Number(params.page));
    params.since && (param.since = params.since);
    params.until && (param.until = params.until);
    params.search && (param.search = params.search);
    params.bank_origin && (param.bank_origin = params.bank_origin);
    params.bank_destiny && (param.bank_destiny = params.bank_destiny);
    params.company && (param.company = params.company);
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["payments"],
        queryFn: (dee) =>
          getPaymentRequest(param).then((d) => {
            setPayments(d.data.results, d.data.count);
          }),
        retry: false,
      })
    );
  };

  useEffect(() => {
    // dispatch(PaymentsThunks());
    handleConsultation();
  }, [params]);

  const handleSearch = ({ search }: any) => {
    if (search == undefined || search == null) return;

    if (search.length == 0) {
      deleteParams(["search"]);
      return;
    }
    const { page, ...rest } = params;
    setSearchParams({ ...rest, search });
  };

  const sendRequestDashboard = () => {
    const parameters = new DashboardParams();

    params.since
      ? (parameters.since = params.since)
      : (parameters.since = firstDayMonth());
    params.until
      ? (parameters.until = params.until)
      : (parameters.until = moment(new Date()).format("DD/MM/YYYY"));
    params.company && (parameters.company = params.company);
    parameters.group_by = "company_id";

    dispatch(DashboardParamsThunks(parameters));
  };

  return (
    <div className="animate-fade-up space-y-5">
      <CardInfoPayments />
      <TemplateTableLayout
        title="Información de los pagos"
        bottons={<BottonsPayments refresh={handleConsultation} />}
        search={handleSearch}
        filters={[
          {
            name: "",
            component: <CheckFilterPayments />,
            field: "status",
          },
          {
            name: "Introduce un rango de fecha",
            component: <SinceUntilPayments />,
            field: "since",
          },
          {
            name: "Selecciona los bancos",
            component: <BanksDestinyOriginPayments />,
            field: "banks",
          },
          {
            name: "Compañía",
            component: <CompaniesFilterPayments />,
            field: "company",
          },
        ]}
      >
        <>
          {params.since && (
            <p className="font-semibold text-xl dark:text-white text-end mt-4">
              {params.since && params.since}
              {params.until && ` – ${params.until}`}
            </p>
          )}
          <TablePayment />
        </>
      </TemplateTableLayout>
    </div>
  );
};

export default PaymentCore;
