import { TableUsuarios } from "./components/TableUsuarios"


export const UsuariosCore = () => {
  return (
    <div className="animate-fade-up">
      <TableUsuarios/>
    </div>
  )
}


