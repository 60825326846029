import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";

import { FaCircle } from "react-icons/fa";

export const InfoCompany = () => {
  const { companies, isLoading } = useSelector((d: RootState) => d.companyID);

  return (
    <>
      <section aria-labelledby="applicant-information-title">
        <div className="my-4 border-2 border-white dark:bg-secondaryDark flex flex-col items-center rounded-xl mx-auto bg-neutral-100 bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:!shadow-none p-3">
         
          <div className="grid grid-cols-2 gap-4 px-2 w-full my-2">
            <div className="flex flex-col items-start justify-center rounded-2xl bg-white dark:bg-primaryDark bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="text-sm text-gray-600">Compañia</p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                {companies?.name}
              </p>
            </div>
            <div className="relative flex flex-col justify-center rounded-2xl bg-white dark:bg-primaryDark bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <span className="absolute right-4 top-4 animate-pulse"><FaCircle className="text-lime-500" /></span>
              <p className="text-sm text-gray-600">Estado  </p>
              <p className="inline-flex justify-between text-base font-medium text-navy-700 dark:text-white uppercase">
                 {companies?.status_name}

              </p>
            </div>
            <div className="flex flex-col justify-center rounded-2xl bg-white dark:bg-primaryDark bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="text-sm text-gray-600">RIF</p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                {companies?.rif}
              </p>
            </div>

            <div className="flex flex-col items-start justify-center rounded-2xl bg-white dark:bg-primaryDark bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="text-sm text-gray-600">Correo</p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                {companies?.email}
              </p>
            </div>

            <div className="flex flex-col items-start justify-center rounded-2xl bg-white dark:bg-primaryDark bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="text-sm text-gray-600">Dirección</p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
               La Guaira, Venezuela
              </p>
            </div>

            <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border dark:bg-primaryDark px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="text-sm text-gray-600">Fecha de Inicio</p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                {companies?.start_date_work}{" "}
              </p>
            </div>
          </div>
          {/* <div className="mt-2 mb-8 w-full rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
              Información General
            </h4>
            <p className="mt-2 px-2 text-base text-gray-600">
              As we live, our hearts turn colder. Cause pain is what we go
              through as we become older. We get insulted by others, lose trust
              for those others. We get back stabbed by friends. It becomes
              harder for us to give others a hand. We get our heart broken by
              people we love, even that we give them all...
            </p>
          </div> */}
        </div>
      </section>
    </>
  );
};