import React, { Component } from "react";
import { BiTransfer } from "react-icons/bi";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { SiZelle } from "react-icons/si";
import { PayMovilPayComponents } from "./PayMovilPayComponents";
import { PayTransferComponents } from "./PayTransferComponents";
import { PayZelleComponents } from "./PayZelleComponents";
import { PayInmediateDebit } from "./PayInmediateDebit";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Tab,
  Tabs,
  useDisclosure,
} from "@nextui-org/react";
import { isMobile } from "react-device-detect";
import { IoMdAdd } from "react-icons/io";

let tabs = [
  {
    id: 1,
    label: "Pago móvil",
    Icon: HiOutlineDevicePhoneMobile,
    Content: PayMovilPayComponents,
  },
  {
    id: 2,
    label: "Transferencia",
    Icon: BiTransfer,
    Content: PayTransferComponents,
  },
  {
    id: 3,
    label: "Zelle",
    Icon: SiZelle,
    Content: PayZelleComponents,
  },
  {
    id: 4,
    label: "Debito Inmediato",
    Icon: FaMoneyBillTransfer,
    Content: PayInmediateDebit,
  },
];
export const ModalInvoicesPay = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <Button color="primary" isIconOnly size="sm" onPress={onOpen}>
        <IoMdAdd className={`font-semibold h-6 w-6 `} />
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size={isMobile ? "md" : "xl"}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Pagar</ModalHeader>
              <ModalBody>
                <Tabs items={tabs} variant="bordered" color="primary">
                  {(item) => (
                    <Tab
                      key={item.id}
                      title={
                        <div className="flex items-center space-x-2">
                          <item.Icon className="h-5 w-5" />
                          <span>{item.label}</span>
                        </div>
                      }
                    >
                      <div className="flex justify-center items-center mb-5 space-x-2">
                        <p className="text-xl">{item.label}</p>
                        <item.Icon className="h-6 w-6" />
                      </div>

                      <item.Content onClose={onClose} />
                    </Tab>
                  )}
                </Tabs>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
