import React, { Component } from "react";
import { RootState } from "../../../../../../../store/store";
import { useSelector } from "react-redux";
import { useAllParams } from "../../../../../../../hooks/useAllParams";
import { PLayouts } from "../../../../../layout/PLayouts";
import { Pagination } from "@nextui-org/react";

export interface Props {
  items: any; // type: Invoices[]
}
export const PaginatorInvoicesCompany = ({ items }: Props) => {
  const {
    addParams,
    deleteParams,
    params: { page },
  } = useAllParams();
  const [pagesItems] = React.useState(1);
  const rowsPerPage = 10;
  const pages = Math.ceil(items.length / rowsPerPage);
  const handlePages = (pageFunction: number) => {
    const start = (pagesItems - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    if (pageFunction == 1) {
      deleteParams(["page"]);
      return;
    }
    addParams({ page: pageFunction });
  };
  

  return (
    <div className="flex w-[100%] lg:justify-between justify-end">
      <div className="lg:flex hidden">
        <PLayouts message={`Total de pago ${items.length}`} />
      </div>
      {items.length > 0 && (
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          size="sm"
          page={!!page ? Number(page) : 1}
          total={pages}
          onChange={handlePages}
        />
      )}
    </div>
  );
};
