import React, { useState } from "react";
import * as yup from "yup";
import { AppDispatch, RootState } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { CreateMethodPaymentThunks } from "../../../../store/payment/thunks";
import { LoadingToast } from "../../../../libs/Notifications";
import { Select, SelectItem, Input, Button, RadioGroup, Radio, cn, DatePicker } from "@nextui-org/react";
import { IoMdAdd } from "react-icons/io";
import { keyFilterNumber } from "../../../../helpers/KeyFIlterMovilPay";
import { ValidateInvoicesThunks } from "../../../../store/invoices/thunks";
import { formaterTimeInternalized, todayInternalized } from "../../../../libs/GetTimeInternalized";

interface Props {
  onClose?: () => void;
}
const schema = yup
  .object({
    email: yup
      .string()
      .required("El email es obligatorio")
      .email("El email no tiene un formato válido"),
    bank: yup.string().required("Debes selecionar el banco"),
    sender: yup.string().required("Debes colocar el Titular de la cuenta"),
    amount: yup.string().required("Debes colocar la Monto pagado"),
    date: yup.date().required("Debes colocar la fecha del Pago"),

  })
  .required();

 
export const PayZelleComponents = ({ onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const { banks } = useSelector((d: RootState) => d.banks);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [dateInput, setDateInput] = useState<any>();
  const onSubmit = (data: any) => {
    const { success, error } = LoadingToast();
    const { email, sender, amount } = data;
    const body = {
      reference: null,
      mobile: null,
      method: 3,
      amount,
      date: dateInput,
      sender,
      email,
    };

    ValidateInvoicesThunks(body)
      .then((result) => {
        success("Pago validado con éxito");
        onClose && onClose();
      })
      .catch(({ response }) => {
        console.error(response.data);
        if (response.status === 400) {
          const { amount, method, date, mobile } = response.data;
          return error(amount || method || date || mobile);
        }
        error("Hubo un error al registrar el Método de Pago");
        return;
      });
  };
  const handleTest = (value: any) => {
    setDateInput(formaterTimeInternalized(value));
  };
  return (
    <form className="space-y-2 my-2" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label>Correo del Zelle</label>
            <Input
              type="email"
              variant="bordered"
              isInvalid={!!errors.email}
              errorMessage={errors.email?.message}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              placeholder="Colocar el correo"
            />
            <span></span>
          </>
        )}
      />

      
      <Controller
        name="sender"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label>Titular de la cuenta Zelle</label>
            <Input
              type="text"
              maxLength={30}
              onChange={onChange}
              variant="bordered"
              isInvalid={!!errors.sender}
              errorMessage={errors.sender?.message}
              onBlur={onBlur}
              value={value}
              placeholder="Colocar el nombre del Titular "
            />
            <span></span>
          </>
        )}
      />
        
        <Controller
        name="date"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label>Fecha del Pago</label>
            <DatePicker
              variant="bordered"
              className=""
              onChange={(e) => {
                handleTest(e);
                onChange(e);
              }}
              isInvalid={!!errors.date}
              errorMessage={errors.date?.message}
              defaultValue={todayInternalized()}
              onBlur={onBlur}
            />
          </>
        )}
      />
      <Controller
        name="amount"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label>Monto del Pago</label>
            <Input
              type="text"
              variant="bordered"
              maxLength={10}
              onChange={(e) => {
                keyFilterNumber({
                  value: e,
                  onChange,
                });
              }}
              isInvalid={!!errors.amount}
              errorMessage={errors.amount?.message}
              onBlur={onBlur}
              value={value}
              placeholder="Colocar el monto del pago"
            />
            <span></span>
          </>
        )}
      />
      <div className="flex justify-end my-5 space-x-5">
        <Button color="danger" variant="light" type="button" onPress={onClose}>
          Close
        </Button>
        <Button
          color="primary"
          endContent={<IoMdAdd className="h-5 w-5" />}
          type="submit"
        >
          Agregar
        </Button>
      </div>
    </form>
  );
};
