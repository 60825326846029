import { Controller, useForm } from "react-hook-form";
import {
  Button,
  CalendarDate,
  DateInput,
  DatePicker,
  Input,
} from "@nextui-org/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IoMdAdd } from "react-icons/io";
import { keyFilterNumber } from "../../../../helpers/KeyFIlterMovilPay";
import { Select, SelectItem } from "@nextui-org/react";
import { AppDispatch, RootState } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { ErrorToast, LoadingToast } from "../../../../libs/Notifications";
import {
  CreateMethodPaymentThunks,
  MethodPaymentsThunks,
} from "../../../../store/payment/thunks";
import { useEffect, useState } from "react";
import { BanksThunks } from "../../../../store/banks/thunks";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { AsyncThunkConfig } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { PaymentParams } from "../../params/payment/payments/paymentParams";
import { useParams } from "react-router-dom";
import { type } from "../methodPayments/typeIdentification";
import { ValidateInvoicesThunks } from "../../../../store/invoices/thunks";
import { DateValue } from "@internationalized/date";
import {
  DateInternalized,
  formaterTimeInternalized,
  todayInternalized,
} from "../../../../libs/GetTimeInternalized";
interface Props {
  onClose?: () => void;
}
const schema = yup
  .object({
    mobile: yup
      .string()
      .required("Debes colocar el numero de teléfono")
      .max(12, "El teléfono tiene un máximo de 11 caracteres"),
    reference: yup
      .string()
      .required("Debes colocar la Referencia")
      .max(6, "La referencia tiene un máximo de 6 caracteres")
      .min(6, ""),
    date: yup.date().required("Debes colocar la fecha del Pago"),
    amount: yup.string().required("Debes colocar la Monto pagado"),
  })
  .required();

const typeItems = type;

export const PayMovilPayComponents = ({ onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [dateInput, setDateInput] = useState<any>();
  const onSubmit = (data: any) => {
    const { success, error } = LoadingToast();
    const { mobile, reference, amount } = data;
    const body = {
      reference,
      mobile: mobile,
      method: 1,
      amount,
      date: dateInput,
    };

    ValidateInvoicesThunks(body)
      .then((result) => {
        success("Pago validado con éxito");
        onClose && onClose();
      })
      .catch(({ response }) => {
        console.error(response.data);
        if (response.status === 400) {
          const { amount, method, date, mobile } = response.data;
          return error(amount || method || date || mobile);
        }
        error("Hubo un error al registrar el Método de Pago");
        return;
      });
  };

  const handleTest = (value: any) => {
    setDateInput(formaterTimeInternalized(value));
  };


  return (
    <form className="space-y-2 my-2" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="mobile"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label>Teléfono</label>
            <Input
              type="text"
              variant="bordered"
              maxLength={12}
              onChange={(e) => {
                keyFilterNumber({
                  value: e,
                  onChange,
                });
              }}
              isInvalid={!!errors.mobile}
              errorMessage={errors.mobile?.message}
              onBlur={onBlur}
              value={value}
              placeholder="Colocar el Teléfono"
            />
            <span></span>
          </>
        )}
      />
      <Controller
        name="reference"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label>Referencia</label>
            <Input
              type="text"
              variant="bordered"
              maxLength={6}
              onChange={(e) => {
                keyFilterNumber({
                  value: e,
                  onChange,
                });
              }}
              isInvalid={!!errors.reference}
              errorMessage={errors.reference?.message}
              onBlur={onBlur}
              value={value}
              placeholder="Colocar los ultimos 6 digitos de la referencia"
            />
            <span></span>
          </>
        )}
      />
      
      <Controller
        name="date"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label>Fecha del Pago</label>
            <DatePicker
              variant="bordered"
              className=""
              onChange={(e) => {
                handleTest(e);
                onChange(e);
              }}
              isInvalid={!!errors.date}
              errorMessage={errors.date?.message}
              defaultValue={todayInternalized()}
              onBlur={onBlur}
            />
          </>
        )}
      />
      <Controller
        name="amount"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label>Monto del Pago</label>
            <Input
              type="text"
              variant="bordered"
              maxLength={10}
              onChange={(e) => {
                keyFilterNumber({
                  value: e,
                  onChange,
                });
              }}
              isInvalid={!!errors.amount}
              errorMessage={errors.amount?.message}
              onBlur={onBlur}
              value={value}
              placeholder="Colocar el monto del pago"
            />
            <span></span>
          </>
        )}
      />
      <div className="flex justify-end my-5 space-x-5">
        <Button color="danger" variant="light" type="button" onPress={onClose}>
          Close
        </Button>
        <Button
          color="primary"
          endContent={<IoMdAdd className="h-5 w-5" />}
          type="submit"
        >
          Agregar
        </Button>
      </div>
    </form>
  );
};
