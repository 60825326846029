import { useDispatch, useSelector } from "react-redux";
import { classNames } from '../../../../../../helpers/ClassN';
import { AppDispatch, RootState } from "../../../../../../store/store";

import { ButtonModalMethodComponents } from "../../../../components/methodPayments";
import { FaMobileAlt } from "react-icons/fa";
import { TbTransfer } from "react-icons/tb";
import { CompaniesIDThunks } from "../../../../../../store/companies/thunks";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { BiTransfer } from "react-icons/bi";
import { SiZelle } from "react-icons/si";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { Payment } from '../../../../interfaces/PaymentInterfaces';
import { PaymentMethodsCompany } from "../../../../interfaces/CompaniesInterfaces";
import { IconType } from "react-icons";

const eventTypes = {
  applied: { icon: FaMobileAlt, bgColorClass: "bg-primary" },
  advanced: { icon: TbTransfer, bgColorClass: "bg-primary" },
};
export interface Props {
  data: any; // type: Payment[]
}

export interface PropsIcons {
  IconToAssign?: IconType | undefined;
}

export interface PaymentMethod {
  label: string;
  Icon: IconType;
  info: string[];
}

// export interface Props {
//   data: any; // type: Payment[]
// }

let paymentsMethods: PaymentMethod[] = [
  {
    label: "PAGOMOVIL",
    Icon: HiOutlineDevicePhoneMobile,
    info: ['identification', 'phone']
  },
  {
    label: "TRANSFERENCIA",
    Icon: BiTransfer,
    info: ['identification']
  },
  {
    label: "ZELLE",
    Icon: SiZelle,
    info: ['identification', 'email']
  },
  {
    label: "Debito Inmediato",
    Icon: FaMoneyBillTransfer,
    info: ['identification']
  },
];

interface PropsPayment {
  data: PaymentMethodsCompany|any
}

const InfoCard = ({ data }: PropsPayment) => {
  const assignIcon = (): PaymentMethod => {
    return paymentsMethods.filter((p) => p.label === data.payment_method_name)[0];
  };

  const assignedIcon = assignIcon();
  return (
    <>
      <IconMethod IconToAssign={assignedIcon.Icon} />
      <h1 className="mt-4 text-xl font-semibold dark:text-gray-300">
        {data.bank_name}
      </h1>

      {assignedIcon && assignedIcon.info.map((a: any) => <p className="mt-2 text-gray-500 dark:text-gray-400">{data[a]}</p>)}
    </>
  )
}

const IconMethod = ({ IconToAssign }: PropsIcons) => {
  return (
    <div className="flex items-center space-x-2 text-lime-600">
      {IconToAssign && (
        <>
          <IconToAssign className="h-20 w-20" />
        </>
      )}
    </div>
  );
};

export const MethodPaymentsCompany = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const { companies, isLoading } = useSelector((d: RootState) => d.companyID);

  useEffect(() => {
    console.log(companies?.payment_methods_companies);
  });

  return (
    <>
      <div className="bg-white px-4 py-5 dark:bg-primaryDark rounded-lg sm:px-6 ">
        <div className="flex items-center justify-between">
          <div></div>
          <ButtonModalMethodComponents />
        </div>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 xl:grid-cols-4 md:p-6 text-center">
          {companies?.payment_methods_companies.map((res) => (
            <div key={res.id} className="flex flex-wrap flex-col items-center justify-center border rounded-lg shadow py-3 md:px-6">
              <InfoCard data={res} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
