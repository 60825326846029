import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../../../../../store/store";
import { useAllParams } from "../../../../../../hooks/useAllParams";
import { PaymentParams } from "../../../../params/payment/payments/paymentParams";
import { InvoicesThunks } from "../../../../../../store/invoices/thunks";
import { useEffect, useState } from "react";
import { Invoices } from "../../../../interfaces/InovicesInterfaces";
import { TableLayout, valueType } from "../../../../layout/TableLayout";
import moment from "moment";
import { PaginatorInvoicesCompany } from "./components/PaginatorInvoicesCompany";
import { ModalInvoicesPay } from "../../../../components/invoicesPay/ModalInvoicesPay";

const dataTable: valueType[] = [
  { name: "Compañía", value: "company_name" },
  {
    name: "Fecha del pago",
    value: (item: any) => {
      return moment(item.date).format("DD-MM-YYYY");
    },
  },

  { name: "Monto", value: "amount" },
  {
    name: "Acción",
    value: (e: any) => {
      return (
        <>
          <ModalInvoicesPay />
        </>
      );
    },
  },
];

export const InvoicesComponent = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [invoices, setInvoices] = useState<Invoices[]>([]);
  const handleConsultation = () => {
    const param = new PaymentParams();
    param.company_id = id;
    dispatch(InvoicesThunks(param)).then((result) => {
      setInvoices(result.payload);
    });
  };
  useEffect(() => {
    handleConsultation();
  }, []);

  return (
    <div className="animate-fade-up space-y-2 my-4 rounded-lg">
      <TableLayout
        columns={dataTable}
        data={invoices}
        isLoading={false}  
        Paginator={<PaginatorInvoicesCompany items={invoices} />}
      />
    </div>
  );
};
