import { FaFileInvoiceDollar, FaHandHoldingUsd } from "react-icons/fa";
import { FaBuildingUser } from "react-icons/fa6";
import { FcOrganization, FcPlus } from "react-icons/fc";
import { MdClearAll } from "react-icons/md";
import { TbTimeline } from "react-icons/tb";

export const companyIDRouters = [
    {
      name: "Seguimientos",
      path: "fallow_up/",
      icon: TbTimeline ,
      superAdmin: false,
      show: true,
      children: null,
    },
    {
      name: "Información del cliente",
      path: "info_company/",
      icon: FaBuildingUser ,
      superAdmin: false,
      show: true,
      children: false,
    },
    {
      name: "Facturas",
      path: "invoices/",
      icon: FaFileInvoiceDollar ,
      superAdmin: false,
      show: true,
      children: false,
    },
    {
      name: "Servicios",
      path: "services/",
      icon: FaHandHoldingUsd ,
      superAdmin: false,
      show: true,
      children: false,
    },
    {
      name: "Métodos de pagos",
      path: "method_payment/",
      icon: MdClearAll ,
      superAdmin: false,
      show: true,
      children: false,
    },
  ];
  