import { Img } from "react-image";
import { useThemeMovilPay } from "../../../../../../hooks/useTheme";
import { Image } from "@nextui-org/react";

const services = [
  {
    title: "Notificación de Pago",
    headline: "Confirmación de Pago",
    methods: ["Pago Movil", "Transferencia", "Zelle"],
  },
  {
    title: "Notificación de Pago",
    headline: "Confirmación de Pago",
    methods: ["Pago Movil", "Transferencia", "Zelle"],
  },
  {
    title: "Notificación de Pago",
    headline: "Confirmación de Pago",
    methods: ["Pago Movil", "Transferencia", "Zelle"],
  },
];

export const ServicesCompany = () => {
  const { darkMode } = useThemeMovilPay();
  const img = darkMode
    ? "logotipo movil play letras todo blanco"
    : "logotipo_movil_play";

  return (
    <>
      {/*    <!-- component -->
      <!-- Create By Joker Banny --> */}
      <div className="my-10 flex justify-center items-center">
        <div className="md:px-4 md:grid md:grid-cols-2 lg:grid-cols-3 gap-5 space-y-0 md:space-y-0">
          {services.map((s, i) => (
            <div
              key={i}
              className="max-w-sm bg-white px-6 pt-6 pb-2 rounded-xl border-2 border-gray-200 shadow-lg transform hover:scale-105 transition duration-500"
            >
              <h3 className="mb-3 text-xl font-bold text-lime-600">
                {s.title}
              </h3>
              <div className="relative">
                <Image
                  alt={s.title}
                  src={require(`../../../../../../assets/img/${img}.png`)}
                />
              </div>
              <h1 className="mt-4 text-lime-600 text-2xl font-bold cursor-pointer">
                {s.headline}
              </h1>
              <div className="my-4">
                {s.methods.map((m,i) => {
                  return (
                    <div className="flex space-x-1 items-center" key={i * 36}>
                      <span>
                        <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg" />
                      </span>
                      <p>{m}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
