import { Controller, useForm } from "react-hook-form";
import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { keyFilterNumber } from "../../../../helpers/KeyFIlterMovilPay";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../store/store";
import { type } from "./typeIdentification";
import { CreateMethodPaymentThunks } from "../../../../store/payment/thunks";
import { useEffect } from "react";
import { LoadingToast } from "../../../../libs/Notifications";
import { BanksThunks } from "../../../../store/banks/thunks";
const schema = yup
  .object({
    account_number: yup
      .string()
      .required("Debes colocar el numero de teléfono")
      .max(20, "El teléfono tiene un máximo de 20 caracteres"),
    bank: yup.string().required("Debes selecionar el banco"),
    identification: yup
      .string()
      .required("Debes colocar tu numero de Identificación")
      .max(10, "Tiene un máximo de 10 caracteres")
      .min(8, "Tiene un máximo de 8 caracteres"),
    account_holder_name: yup.string().required("Debes colocar el Titular de la cuenta"),
    type: yup.string().required("Debes colocar el Tipo de Identificación"),
  })
  .required();

interface Props {
  onClose?: () => void;
}
const typeItems = type;

export const FormTransferComponents = ({ onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const { banks } = useSelector((d: RootState) => d.banks);
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // Captura los valores de la etiqueta Select
  const valueBank = watch("bank");
  const valueType = watch("type");
  // Funciones para manejar los eventos del formulario
  const handleChangeBank = (e: any) => setValue("bank", e.target.value);
  const handleChangeType = (e: any) => setValue("type", e.target.value);
  const onSubmit = (data: any) => {
    const { success, error } = LoadingToast();
    const { account_number, bank, identification, type, account_holder_name } = data;
    const body = {
      method: true,
      payment_methods: [
        {
          email: null,
          phone:null,
          payment_method: 2,
          bank,
          account_number,
          account_holder_name,
          identification: type.concat(identification),
        },
      ],
    };
    CreateMethodPaymentThunks(body, id)
      .then((result) => {
        success(" Método de Pago registrado con éxito");
        onClose && onClose();
      })
      .catch(({ response }) => {
        console.error(response.data);
        if (response.status === 400) {
          response.data.map((d: any) => {
            Object.keys(d).map((e) => {
              const message = d[e];
              return error(e + " " + message);
            });
          });
        }
        error("Hubo un error al registrar el Método de Pago");
        return
      });
  };
 
  useEffect(() => {
    dispatch(BanksThunks());
  }, []);

  return (
    <form className="space-y-2 my-2" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="bank"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label> Banco </label>
            <Select
              variant="bordered"
              items={banks}
              placeholder="Seleccione el banco"
              onChange={handleChangeBank}
              value={value}
              isInvalid={!!errors.bank}
              errorMessage={errors.bank?.message}
              onBlur={onBlur}
            >
              {(bank) => (
                <SelectItem value={bank.id} key={bank.id}>
                  {bank.name}
                </SelectItem>
              )}
            </Select>
            <span></span>
          </>
        )}
      />
      <Controller
        name="account_number"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label>Nro. Cuenta</label>
            <Input
              type="text"
              variant="bordered"
              maxLength={20}
              onChange={(e) => {
                keyFilterNumber({
                  value: e,
                  onChange,
                });
              }}
              isInvalid={!!errors.account_number}
              errorMessage={errors.account_number?.message}
              onBlur={onBlur}
              value={value}
              placeholder="XXXX XXXX XXXX XXXX XXXX "
            />
            <span></span>
          </>
        )}
      />
      <label>Identificación</label>
      <div className="flex items-center  ">
        <Controller
          name="type"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <Select
                variant="bordered"
                items={typeItems}
                className="w-24"
                isInvalid={!!errors.type}
                errorMessage={errors.type?.message}
                onBlur={onBlur}
                value={value}
                onChange={handleChangeType}
              >
                {(type) => (
                  <SelectItem key={type.label} value={type.label}>
                    {type.label}
                  </SelectItem>
                )}
              </Select>
              <span></span>
            </>
          )}
        />
        <Controller
          name="identification"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <Input
                type="text"
                label=""
                variant="bordered"
                maxLength={10}
                minLength={6}
                onChange={(e) => {
                  keyFilterNumber({
                    value: e,
                    onChange,
                  });
                }}
                isInvalid={!!errors.identification}
                errorMessage={errors.identification?.message}
                onBlur={onBlur}
                value={value}
                placeholder="Colocar la identificación"
              />
              <span></span>
            </>
          )}
        />
      </div>
      <Controller
        name="account_holder_name"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label>Titular de la cuenta</label>
            <Input
              type="text"
              maxLength={30}
              onChange={onChange}
              variant="bordered"
              isInvalid={!!errors.account_holder_name}
              errorMessage={errors.account_holder_name?.message}
              onBlur={onBlur}
              value={value}
              placeholder="Colocar el nombre del Titular "
            />
            <span></span>
          </>
        )}
      />
      <div className="flex justify-end my-5 space-x-5">
        <Button color="danger" variant="light" type="button" onPress={onClose}>
          Close
        </Button>
        <Button
          color="primary"
          endContent={<IoMdAdd className="h-5 w-5" />}
          type="submit"
        >
          Agregar
        </Button>
      </div>
    </form>
  );
};
