import { createAsyncThunk } from "@reduxjs/toolkit";
import { movilPayAPI } from "../../api/movilPayAPI";
import { PaymentParams } from "../../pages/core/params/payment/payments/paymentParams";

export const InvoicesThunks = createAsyncThunk(
    "Invoices",
    async (params?: PaymentParams) => {
      return movilPayAPI
        .get("/companies/invoices/", { params })
        .then((result) => {
          return result.data;
        })
        .catch((err) => {
          return [];
        });
    }
  );
  export const ValidateInvoicesThunks=(body:any)=>{
    return movilPayAPI.post(`/payments/validate/`,body)
  }