import { HeaderCompany } from "./components/HeaderCompany";
import { configTaiwind } from "../../../../../utils/configTaiwind";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CompaniesIDThunks } from "../../../../../store/companies/thunks";
import { AppDispatch } from "../../../../../store/store";
import { TabsCompany } from "./components/TabsCompany";
import { InvoiceInfo } from './components/InvoiceInfo';


export const CompanyCore = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  // const company = useSelector((d: RootState) => );
  // const { company, setCompany }:any = useState();
  let company:any;
  const handleSearch = () => {
    dispatch(CompaniesIDThunks({ id })).then((result) => {
      company = result.payload;
    });
  };

  useEffect(() => {
    if (id) handleSearch();
  }, []);

  return (
    <>
      <main className={configTaiwind.animateView}>
        {/* Page header */}
        <HeaderCompany />
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-full">
            {/* Información de la compañía */}
            <InvoiceInfo />
          </div>
          <div className="col-span-full">
            {/* Gráfica de los ingresos de la compañía */}
          </div>
          <div className="col-span-full ">
            {/* Método de pago */}
            {/* <MethodPaymentsCompany  data={company} /> */}
            <TabsCompany  />
          </div>
        </div>
      </main>
    </>
  );
};
