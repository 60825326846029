import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  DatePicker,
  Input,
  Radio,
  RadioGroup,
  Select,
  SelectItem,
} from "@nextui-org/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { keyFilterNumber } from "../../../../helpers/KeyFIlterMovilPay";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../store/store";
import { type } from "../methodPayments/typeIdentification";
import { CreateMethodPaymentThunks } from "../../../../store/payment/thunks";
import { useEffect } from "react";
import { LoadingToast } from "../../../../libs/Notifications";
import { BanksThunks } from "../../../../store/banks/thunks";
import { ValidateInvoicesThunks } from "../../../../store/invoices/thunks";
import {
  DateInternalized,
  formaterTimeInternalized,
  todayInternalized,
} from "../../../../libs/GetTimeInternalized";
import moment from "moment";

const schema = yup
  .object({
    account_number: yup
      .string()
      .required("Debes colocar el numero de teléfono")
      .max(20, "El teléfono tiene un máximo de 20 caracteres"),
    phone: yup
      .string()
      .required("Debes colocar el numero de teléfono")
      .max(12, "El teléfono tiene un máximo de 11 caracteres"),
    bank: yup.string().required("Debes selecionar el banco"),
    option: yup.string().required("Debes seleccionar una opción"),
    autorization: yup.string().required("Debes seleccionar una opción"),
    codeSM: yup.string(),
    amount: yup.string().required("Debes colocar la Monto pagado"),
    date: yup.date().required("Debes colocar la fecha del Pago"),
  })
  .required();

interface Props {
  onClose?: () => void;
}

const typeItems = type;
export const PayInmediateDebit = ({ onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const { companies } = useSelector((d: RootState) => d.companyID);
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [bank, setBank] = useState<any[]>([]);
  const [auth, setAuth] = useState<any[]>([
    {
      label: "Mediante SMS",
      value: "sms",
    },
    {
      label: "En linea",
      value: "online",
    },
  ]);
  // Captura los valores de la etiqueta Select
  const valueBank = watch("bank");
  const valueOption = watch("option");
  const valueAuth = watch("autorization");
  // Funciones para manejar los eventos del formulario
  const handleChangeBank = (e: any) => setValue("bank", e.target.value);
  const handleOption = (e: any) => setValue("option", e.target.value);
  const handleAutorization = (e: any) =>
    setValue("autorization", e.target.value);
  const [dateInput, setDateInput] = useState<any>();
  const onSubmit = (data: any) => {
    const { success, error } = LoadingToast();
    const { phone, account_number, amount, } = data;
    const body = {
      reference: null,
      mobile: phone,
      sender: account_number,
      method: 4,
      amount,
      date: moment(dateInput).format("YYYY-MM-DD") ,
    };
    ValidateInvoicesThunks(body)
      .then((result) => {
        success("Pago validado con éxito");
        onClose && onClose();
      })
      .catch(({ response }) => {
        console.error(response.data);
        if (response.status === 400) {
          const { amount, method, date, mobile } = response.data;
          return error(amount || method || date || mobile);
        }
        error("Hubo un error al registrar el Método de Pago");
        return;
      });
  };
  const handleTest = (value: any) => {
    console.log(value);
    console.log(new Date(formaterTimeInternalized(value)));
    
    setDateInput(value );
    console.log(dateInput);
  };
  useEffect(() => {
    setBank(companies?.payment_methods_companies || []);
  }, []);
  return (
    <form className="space-y-4 my-2" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="bank"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label> Banco </label>
            <Select
              variant="bordered"
              items={bank}
              placeholder="Seleccione el banco"
              onBlur={onBlur}
              value={value}
              onChange={handleChangeBank}
              isInvalid={!!errors.bank}
              errorMessage={errors.bank?.message}
            >
              {(bank) => (
                <SelectItem value={bank.bank_name} key={bank.bank}>
                  {bank.bank_name}
                </SelectItem>
              )}
            </Select>
            <span></span>
          </>
        )}
      />
      <Controller
        name="option"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label>Seleccione una opción </label>
            <RadioGroup
              value={value}
              orientation="horizontal"
              onChange={handleOption}
            >
              <Radio value="account">Cuenta</Radio>
              <Radio value="mobile">Teléfono</Radio>
            </RadioGroup>

            <span></span>
          </>
        )}
      />
      {valueOption === "account" ? (
        <>
          <Controller
            name="account_number"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <label>Nro. Cuenta</label>
                <Input
                  type="text"
                  variant="bordered"
                  maxLength={20}
                  onChange={(e) => {
                    keyFilterNumber({
                      value: e,
                      onChange,
                    });
                  }}
                  isInvalid={!!errors.account_number}
                  errorMessage={errors.account_number?.message}
                  onBlur={onBlur}
                  value={value}
                  placeholder="XXXX XXXX XXXX XXXX XXXX "
                />
                <span></span>
              </>
            )}
          />
        </>
      ) : valueOption === "mobile" ? (
        <>
          <Controller
            name="phone"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <label>Teléfono</label>
                <Input
                  type="text"
                  variant="bordered"
                  maxLength={12}
                  onChange={(e) => {
                    keyFilterNumber({
                      value: e,
                      onChange,
                    });
                  }}
                  isInvalid={!!errors.phone}
                  errorMessage={errors.phone?.message}
                  onBlur={onBlur}
                  value={value}
                  placeholder="Colocar el Teléfono"
                />
                <span></span>
              </>
            )}
          />
        </>
      ) : (
        <></>
      )}
      <Controller
        name="date"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label>Fecha del Pago</label>
            <DatePicker
              variant="bordered"
              className=""
              onChange={(e) => {
                handleTest(e)
                onChange(e);
              }}
              isInvalid={!!errors.date}
              errorMessage={errors.date?.message}
              defaultValue={todayInternalized()}
              onBlur={onBlur}
            />
          </>
        )}
      />
        <Controller
        name="amount"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label>Monto del Pago</label>
            <Input
              type="text"
              variant="bordered"
              maxLength={10}
              onChange={(e) => {
                keyFilterNumber({
                  value: e,
                  onChange,
                });
              }}
              isInvalid={!!errors.amount}
              errorMessage={errors.amount?.message}
              onBlur={onBlur}
              value={value}
              placeholder="Colocar el monto del pago"
            />
            <span></span>
          </>
        )}
      />
      <Controller
        name="autorization"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <label> Autorización del pago por: </label>
            <Select
              variant="bordered"
              items={auth}
              placeholder="Seleccione el banco"
              onBlur={onBlur}
              value={value}
              onChange={handleAutorization}
              isInvalid={!!errors.autorization}
              errorMessage={errors.autorization?.message}
            >
              {(bank) => (
                <SelectItem value={bank.label} key={bank.value}>
                  {bank.label}
                </SelectItem>
              )}
            </Select>
            <span></span>
          </>
        )}
      />
      {valueAuth === "sms" ? (
        <Controller
          name="codeSM"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <label>Código SMS</label>
              <Input
                type="text"
                variant="bordered"
                maxLength={6}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder="Código SMS"
              />
              <span></span>
            </>
          )}
        />
      ) : (
        <></>
      )}
    
      <div className="flex justify-end my-5 space-x-5">
        <Button color="danger" variant="light" type="button" onPress={onClose}>
          Close
        </Button>
        <Button
          color="primary"
          endContent={<IoMdAdd className="h-5 w-5" />}
          type="submit"
        >
          Agregar
        </Button>
      </div>
    </form>
  );
};
